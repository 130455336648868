import './_bootstrap';

jQuery(document).ready(function () {

	// eslint-disable-next-line no-console
	console.log('Everything is ready. ThemePlate!');
	var limit = 3;

	jQuery('[type="checkbox"]').on('change', function(e) {

			const isChecked = jQuery(this).val();

			if (jQuery('[type="checkbox"]:checked').length > limit) {

				jQuery(this).prop('checked', false);

				alert("You can only select up to 3 options");

				return false;
			}
	});

	jQuery('form.checkout').on('change', 'input[name^="shipping_method"]', function() {

        var selectedMethod = jQuery('input[name^="shipping_method"]:checked').val();

        if(selectedMethod == 'local_pickup:2') {

			jQuery('#billing_address_1_field').hide();
            jQuery('#billing_address_2_field').hide();
			jQuery('#billing_city_field').hide();

			jQuery('#billing_address_1').val('The Boulevard, Al Habtoor City, Dubai, UAE - LOACL PICK UP');
            jQuery('#billing_address_2').val('The Boulevard');
			jQuery('#billing_city').val('next to All Day Supermarket');


        } else if(selectedMethod == 'free_shipping:1') {

			var billing = jQuery('#billing_address_1').val();

			if(billing == 'The Boulevard, Al Habtoor City, Dubai, UAE - LOACL PICK UP') {
				jQuery('#billing_address_1').val('');
            	jQuery('#billing_address_2').val('');
				jQuery('#billing_city').val('');
			}

            jQuery('#billing_address_1_field').show();
            jQuery('#billing_address_2_field').show();
			jQuery('#billing_city_field').show();

        }
    });

	jQuery("#cart-cappozpizza").on("submit", function (e) {

		if (jQuery("input[type='radio']").length > 0) {
			if (!jQuery("input[type='radio']:checked").val()) {

				alert('Please select a bread option.');

				e.preventDefault();
				// prevent form from submitting
			}
		}

	});

	// during load
	setTimeout(function(){
		if (jQuery('input[name^="shipping_method"]').length > 0) {

			var selectedMethod = jQuery('input[name^="shipping_method"]:checked').val();

			if(selectedMethod == 'local_pickup:2') {

				jQuery('#billing_address_1').val('');
				jQuery('#billing_address_2').val('');
				jQuery('#billing_city').val('');

				jQuery('#billing_address_1').val('The Boulevard, Al Habtoor City, Dubai, UAE - LOACL PICK UP');
				jQuery('#billing_address_2').val('The Boulevard');
				jQuery('#billing_city').val('next to All Day Supermarket');

				jQuery('#billing_address_1_field').hide();
				jQuery('#billing_address_2_field').hide();
				jQuery('#billing_city_field').hide();

			} else if(selectedMethod == 'free_shipping:1') {

				var billing = jQuery('#billing_address_1').val();

				if(billing == 'The Boulevard, Al Habtoor City, Dubai, UAE - LOACL PICK UP') {
					jQuery('#billing_address_1').val('');
					jQuery('#billing_address_2').val('');
					jQuery('#billing_city').val('');
				}

				jQuery('#billing_address_1_field').show();
				jQuery('#billing_address_2_field').show();
				jQuery('#billing_city_field').show();
			}
		}
	}, 1000);
});


jQuery('.burger-menu#menu').click(function () {

	jQuery('body').toggleClass('sitenav-mobile-active');
	jQuery('.top-bar-wrapper').toggleClass('sitenav-mobile-active');

	if (jQuery('body').hasClass('sitenav-mobile-active')) {
		jQuery(this).find('.text').text('Close');
		jQuery('body').css('overflow', 'hidden');
		jQuery('.top-bar-wrapper').hide();
	} else {
		jQuery(this).find('.text').text('Menu');
		jQuery('body').css('overflow', 'auto');
		jQuery('.top-bar-wrapper').show();
	}

	var $backdrop = jQuery('.layout-backdrop');

  	$backdrop.attr('aria-hidden',
  	$backdrop.attr('aria-hidden')=='false' ? 'true' : 'false'
  );

	jQuery('#sitenav-mobile').toggleClass('open');

	jQuery('#sitenav-mobile').removeClass('expand');

});
